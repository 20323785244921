import React from "react"
import { Router } from "@reach/router"
import { withPrefix } from "gatsby"

import { Layout } from "../../components"
import Form from "./form"
import ExcursionForm from "./excursion-form"

const Index: React.FC = () => {
  return (
    <Layout>
      <div className="container">
        <Router basepath={withPrefix("form")}>
          <Form path="/regular/:courseId" />
          <ExcursionForm path="/excursion/:excursionLessonId" />
        </Router>
      </div>
    </Layout>
  )
}

export default Index
