import React from "react"
import { RouteComponentProps } from "@reach/router"
import { useForm } from "react-hook-form"
import { connect, ConnectedProps } from "react-redux"
import { navigate } from "gatsby"

import { store, route } from "@/types/interface"
import { courseFind, applicationSubmit } from "../../actions"
import styles from "./form.module.css"

const mediums = [
  {
    id: 'sns',
    name: 'SNS（Instagram, Facebook等）',
  },
  {
    id: 'paper',
    name: 'カルチャーハウスの紙広告'
  },
  {
    id: 'dm',
    name: 'カルチャーハウスからのDM'
  },
  {
    id: 'internet',
    name: 'インターネット検索'
  },
  {
    id: 'friend',
    name: '知人からの紹介',
  },
  {
    id: 'other',
    name: 'その他（以下の自由回答欄に記載お願いいたします）'
  }
] as const

const connector = connect(
  // mapStateToProps
  ({
    course,
    siteInformation,
  }: {
    course: store.Course
    siteInformation: store.SiteInformation
  }) => ({
    courseDetail: course?.courseDetail,
    siteInformation: siteInformation.siteInformation,
  }),
  // mapDispatchToProps
  { courseFind, applicationSubmit }
)

type Props = RouteComponentProps<{ courseId: string }> &
  ConnectedProps<typeof connector>

const Form: React.FC<Props> = ({
  courseDetail,
  siteInformation,
  courseId,
  courseFind,
  applicationSubmit,
}) => {
  React.useEffect(() => {
    if (courseId) {
      courseFind(courseId)
    } else {
      navigate("/")
    }
  }, [])

  const { register, handleSubmit, errors, formState } = useForm()
  const { isSubmitting } = formState
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const onSubmit = (data: any) => {
    const application: route.Application = {
      ...data,
      courseTitle: courseDetail?.courseTitle ? courseDetail.courseTitle : "",
      medium: data.medium === 'other' ? `その他：${data.otherText}` : mediums.find(medium => medium.id === data.medium)?.name || '',
    }
    const confirmMsg = `以下の内容で送信しますか？\n\n講座名: ${application.courseTitle}\n体験日: ${application.trialDate}\nお名前: ${application.name}\n生年月日: ${application.birth}\n電話番号: ${application.tel}\nメールアドレス: ${application.mail}\n住所: ${application.address}\n会員番号: ${application.memberNumber}`
    if (window.confirm(confirmMsg)) {
      applicationSubmit(application)
        .then(application => {
          window.alert("送信しました。")
          setIsSubmitted(true)
        })
        .catch(error => {
          window.alert(
            "メールの送信に失敗しました。\nお手数ですが、時間をおいて再度送信していただくか、お問い合わせ先のお電話にてご予約をお願いいいたします。"
          )
        })
    }
  }

  return (
    <form className={styles.formGroup} onSubmit={handleSubmit(onSubmit)}>
      <label>講座名</label>
      <p>{courseDetail?.courseTitle}</p>
      <label>体験日</label>
      <select name="trialDate" ref={register({ required: true })}>
        {courseDetail?.trials.map(trial => (
          <DateCandidate key={trial._id} trial={trial}></DateCandidate>
        ))}
      </select>
      <label className={styles.must}>お名前</label>
      <input name="name" ref={register({ required: true })} />
      {errors.name && <span className={styles.error}>入力が必要です。</span>}
      <label className={styles.must}>生年月日(西暦)</label>
      <input
        name="birth"
        placeholder="例) 1994/05/02"
        ref={register({ required: true })}
      />
      {errors.birth && <span className={styles.error}>入力が必要です。</span>}
      <label className={styles.must}>電話番号(日中繋がりやすい番号)</label>
      <input
        name="tel"
        placeholder="例) 090-****-****"
        ref={register({ required: true })}
      />
      {errors.tel && <span className={styles.error}>入力が必要です。</span>}
      <label className={styles.must}>メールアドレス</label>
      <input name="mail" type="email" ref={register({ required: true })} />
      {errors.mail && <span className={styles.error}>入力が必要です。</span>}
      <label className={styles.must}>住所</label>
      <input
        name="address"
        placeholder="例) 大阪府枚方市香里ヶ丘 3-11-1"
        ref={register({ required: true })}
      />
      {errors.address && <span className={styles.error}>入力が必要です。</span>}
      <label>会員番号(会員の方のみ)</label>
      <input name="memberNumber" ref={register()} />
      <label className={styles.must}>何をみてお申し込みいただきましたか？</label>
      {mediums.map(medium => (
        <div key={medium.id} className={styles.medium}>
          <input id={medium.id} type="radio" name="medium" value={medium.id} ref={register({ required: true })} /><label htmlFor={medium.id}>{medium.name}</label>
        </div>
      ))}
      <input name="otherText" placeholder="（自由回答欄）" ref={register()} />
      {errors.medium && <span className={styles.error}>選択が必要です。</span>}

      <input
        className={styles.submit}
        type="submit"
        disabled={isSubmitting || isSubmitted}
        value="送信"
      />
      {isSubmitted && (
        <div className={styles.complete}>
          申込みを送信しました。
          <br />
          <br />
          申込受付が完了次第、ご入力いただいたメールアドレスへ申込完了通知メールを送信いたします。
          <br />
          <br />
          しばらく経っても申込完了通知メールが届かない場合、
          <br />
          申込みが完了していない可能性がございますので、
          <br />
          お手数をおかけしますが、下記お電話までご連絡をお願いいたします。
          <br />
          <br />
          <a href={`tel:${siteInformation?.tel}`}>Tel: {siteInformation?.tel}</a>
          <br />
          <br />
          ※ メールの設定によっては通知メールが迷惑メールフォルダに入っているケースがございます。
          <br />
          　【@culture-house.com】からの受信許可を設定いただきますようお願い申し上げます。
        </div>
      )}
    </form>
  )
}

type DateCandidateProps = {
  trial: route.Trial
}

const DateCandidate: React.FC<DateCandidateProps> = ({ trial }) => {
  return (
    <>
      {trial.dateDisplays.map((date, i) => (
        <option
          key={`${trial._id}${i}`}
        >{`${trial.title || ''} ${date} ${trial.timeDisplays[i]}`}</option>
      ))}
    </>
  )
}

export default connector(Form)
